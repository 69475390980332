<template>
  <div class="main">
    <div class="container">
      <div class="trxTitle productListTitle">
        <h1>{{ activeLanguage.productList }}</h1>
        <button type="submit" class="btn productListTitleBtn" @click="goToNewProduct">{{
            activeLanguage.addNewProduct
          }}
        </button>
      </div>

      <div class="trxMainTables">
        <div class="trxTable">
          <div class="col-12" style="margin-top: 1rem; margin-bottom: 2rem;">
            <input type="text" v-model="search" class="form-control trxSearchInput" style="font-size: 16px !important;"
                   @change="getProducts()" :placeholder="activeLanguage.search">
          </div>
          <div class="">
            <div class="trxProductListTable">
              <div class="row trxTableHeader">
                <div class="col-1 trxProductListTableTh">ID</div>
                <div class="col-2 trxProductListTableTh">{{ activeLanguage.productName }}</div>
                <div class="col-2 trxProductListTableTh">{{ activeLanguage.price }}</div>
                <div class="col-6 trxProductListTableTh">{{ activeLanguage.description }}</div>
                <div class="col-1 trxProductListTableTh"></div>
              </div>

              <div class="row trxProductListTableTbodyTr" v-for="(product, index) in productList" :key="product.id">
                <template v-if="!product.expand">
                  <div class="col-1 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ product.product_id }}
                    </p>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ product.name }}
                    </p>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      € {{ formattedAmount(product.price) }}
                    </p>
                  </div>
                  <div class="col-6 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ product.description }}
                    </p>
                  </div>
                  <div class="col-1 trxProductListTableTd trxArrow">
                    <i class="fa fa-angle-down" aria-hidden="true" @click="expandItem(index)"></i>
                  </div>
                </template>

                <template v-if="product.expand && !product.edit">
                  <div class="col-1 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ product.product_id }}
                    </p>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ product.name }}
                    </p>
                    <div class="logoField">
                      <a
                          @click.prevent="deleteLogo()"
                          v-if="!!product.image"
                          href=""
                          class="deleteLogo hidden-xs"
                      >
                        <i class="fas fa-times-circle" aria-hidden="true"></i>
                      </a>
                      <div class="logo offset-md-4" style="display: contents;" v-if="!!product.image">
                        <img :src="product.image" class="logoImg"/>
                      </div>
                      <div class="logoInner" v-else>
                        <p>Drag and drop your image here</p>
                        <p>{{ activeLanguage.maxFileSize }} 2MB</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      € {{ product.price }}
                    </p>
                    <p class="trxProductListTableP">
                      {{ activeLanguage.vat }}: {{ product.btw_percentage }} %
                    </p>
                  </div>
                  <div class="col-6 trxProductListTableTd">
                    <p class="trxProductListTableP" style="margin-bottom: 0;">
                      {{ product.description }}
                    </p>
                    <div style="display: flex;">
                      <div class="col-8" style="padding-left: 0;">
                        <div class="trxSizes">
                          <p class="trxProductListTableP" style="width: 75px;">
                            Size (cm)
                          </p>
                          <p class="trxProductListTableP">
                            : H {{ product.size_height }}
                          </p>
                          <p class="trxProductListTableP">
                            W {{ product.size_width }}
                          </p>
                          <p class="trxProductListTableP">
                            L {{ product.size_length }}
                          </p>
                        </div>
                        <div class="trxSizes">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: 0;">
                            Weight (kg)
                          </p>
                          <p class="trxProductListTableP" style="margin-top: 0;">
                            : {{ product.weight }} Kg
                          </p>
                        </div>
                        <div class="trxSizes">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: 0;">
                            QR image
                          </p>
                          <p class="trxProductListTableP" style="margin-top: 0;">
                            : <a :href=product.qr style="text-decoration: underline">QR Image Link</a>
                          </p>
                        </div>
                        <div class="trxSizes" v-for="field in custom_fields">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: 0;">
                            {{ field.label }}
                          </p>
                          <p class="trxProductListTableP" style="margin-top: 0;">
                            : {{ field.value }}
                          </p>
                        </div>
                        <div class="trxSizes" v-for="variation in variations">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: 0;">
                            {{ variation.name }} :
                          </p>
                          <p class="trxProductListTableP" style="margin-top: 0;" v-for="option in variation">
                            {{ option.value }}
                          </p>
                        </div>
                      </div>
                      <div class="col-4" style="padding-left: 0; padding-right: 0;">
                        <img :src="product.qr" class="img-responsive" alt="" width="150">
                        <p class="trxProductListTableP" style="margin-top: 0; margin-bottom: 5px; margin-left: 7px">
                          {{ activeLanguage.timesScanned }}: {{ product.scanned_times }}</p>
                        <p class="trxProductListTableP" style="margin-top: 0; margin-left: 7px">
                          {{ activeLanguage.placedOrders }}: {{ product.order_created_times }}</p>
                      </div>
                    </div>
                    <div class="btns">
                      <div class="text-right" v-if="!product.edit" style="margin-bottom: 15px">
                        <button type="button" class="btn btn-danger productListTitleBtn trxDeleteBtn"
                                style="width: auto; margin-right: 10px;" @click="deleteItem(product)">
                          {{ activeLanguage.delete }}
                        </button>
                        <button class="btn productListTitleBtn" style="width: auto;" @click="editItem(index)">
                          {{ activeLanguage.edit }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 trxProductListTableTd trxArrow">
                    <i class="fa fa-pencil" aria-hidden="true" style="margin-right: 15px" @click="editItem(index)"></i>
                    <i class="fa fa-angle-up" aria-hidden="true" @click="expandItem(index)"></i>
                  </div>
                </template>

                <template v-if="product.expand && product.edit">
                  <div class="col-1 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ product.product_id }}
                    </p>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      <input type="text" v-model="product.name" class="form-control" id="name" minlength="3" required>
                    </p>
                    <div class="logoContainer" @dragover.prevent @drop.prevent>
                      <div class="logoField" @drop="dragFile($event, index)">
                        <input type="file" @change="uploadLogo($event, index)" class="customFileInput col-11"/>
                        <div class="logo offset-md-4" style="display: contents;" v-if="!!product.image">
                          <img :src="product.image" class="logoImg"/>
                        </div>
                        <div class="logoInner" v-else>
                          <p>Drag and drop your image here</p>
                          <p>{{ activeLanguage.maxFileSize }} 2MB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <div class="input-group" style='margin-top: 15px'>
                      <div class="input-group-addon trxInputAddon trxAddonIcon">€</div>
                      <input type="text" v-model="product.price" class="form-control" id="price" required>
                    </div>
                    <div class="input-group" style='margin-top: 10px'>
                      <div class="input-group-addon trxInputAddon trxAddonIcon">%</div>
                      <select name="btw" id="btw" v-model="product.btw_percentage" class="form-control"
                              style="height: 35px" required>
                        <option value="0">0</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="9">9</option>
                        <option value="12">12</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 trxProductListTableTd">
                                        <textarea
                                            name="description"
                                            id="description"
                                            cols="20"
                                            rows="5"
                                            v-model="product.description"
                                            class="form-control"
                                            style="max-height: 125px; margin-top: 15px"
                                            required
                                        ></textarea>
                    <div style="display: flex;">
                      <div class="col-8" style="padding-left: 0;">
                        <div class="trxSizesEdit">
                          <p class="trxProductListTableP" style="width: 75px;">
                            Size (cm)
                          </p>
                          <div class="input-group trxSizesInput" style="margin-left: 15px;">
                            <div class="input-group-addon trxInputAddon trxInputSizes">H</div>
                            <input type="text" v-model="product.size_height" class="form-control" id="H"/>
                          </div>
                          <div class="input-group trxSizesInput">
                            <div class="input-group-addon trxInputAddon trxInputSizes">W</div>
                            <input type="text" v-model="product.size_width" class="form-control" id="W"/>
                          </div>
                          <div class="input-group trxSizesInput">
                            <div class="input-group-addon trxInputAddon trxInputSizes">L</div>
                            <input type="text" v-model="product.size_length" class="form-control" id="L"/>
                          </div>
                        </div>
                        <div class="trxSizesEdit">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: auto; margin-bottom: auto">
                            Weight (kg)
                          </p>
                          <div class="input-group" style="margin-left: 15px; width: 100px">
                            <div class="input-group-addon trxInputAddon trxInputSizes">Kg</div>
                            <input type="text" v-model="product.weight" class="form-control" id="H"/>
                          </div>
                        </div>
                        <div class="trxSizes" style="margin-top: 15px">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: 0;">
                            QR image
                          </p>
                          <p class="trxProductListTableP" style="margin-top: 0;">
                            : <a :href=product.qr style="text-decoration: underline">QR Image Link</a>
                          </p>
                        </div>
                        <div class="trxSizes" v-for="field in custom_fields">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: 0;">
                            {{ field.label }}
                          </p>
                          <p class="trxProductListTableP" style="margin-top: 0;">
                            : {{ field.value }}
                          </p>
                        </div>
                        <div class="trxSizes" v-for="variation in variations">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: 0;">
                            {{ variation.name }} :
                          </p>
                          <p class="trxProductListTableP" style="margin-top: 0;" v-for="option in variation">
                            {{ option.value }}
                          </p>
                        </div>
                      </div>
                      <div class="col-4" style="padding-left: 0; padding-right: 0;">
                        <img :src="product.qr" class="img-responsive" alt="" width="150">
                        <p class="trxProductListTableP" style="margin-top: 0; margin-bottom: 5px; margin-left: 7px">
                          {{ activeLanguage.timesScanned }}: {{ product.scanned_times }}</p>
                        <p class="trxProductListTableP" style="margin-top: 0; margin-left: 7px;  margin-bottom: 5px;">
                          {{ activeLanguage.placedOrders }}: {{ product.order_created_times }}</p>
                        <p class="trxProductListTableP" style="margin-top: 0; margin-left: 7px">Rejected orders:
                          {{ product.credit_check_rejected_times }}</p>
                      </div>
                    </div>
                    <div class="btns">
                      <div class="text-right" v-if="product.edit" style="margin-bottom: 15px">
                        <button type="button" class="btn btn-secondary productListTitleBtn"
                                style="width: auto; margin-right: 10px;" @click="editItem(index)">
                          {{ activeLanguage.cancel }}
                        </button>
                        <button class="btn productListTitleBtn" style="width: auto;" @click="saveItem(product)">
                          {{ activeLanguage.save }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 trxProductListTableTd trxArrow">
                    <i class="fa fa-pencil" aria-hidden="true" style="margin-right: 15px" @click="editItem(index)"></i>
                    <i class="fa fa-angle-up" aria-hidden="true" @click="expandItem(index)"></i>
                  </div>
                </template>
              </div>
              <div class="row" v-if="pagination.productsPerPage < pagination.maxItems">
                <div class="col-5"></div>
                <div class="col-4 trxPagination">
                  <p class="trxProductListTableP">Rows per page</p>
                  <select name="productsPerPage" id="productsPerPage" v-model="pagination.productsPerPage"
                          class="form-control productsPerPage">
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div class="col-3 paginationInfo">
                  <div class="pagCount col-6">
                    <p class="trxProductListTableP">{{ pageCount }}</p>
                  </div>
                  <div class="paginationArrows col-3">
                    <i class="fa fa-angle-left" aria-hidden="true" @click="getPrevPage"></i>
                    <i class="fa fa-angle-right" aria-hidden="true" @click="getNextPage"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'TrxProductList',
  data() {
    return {
      search: null,
      productList: [],
      pagData: {
        currentPage: 1,
        maxItems: 0,
      },
      csvIds: [],
      productsPerPage: 20,
      pagination: {
        currentPage: 1,
        maxItems: 0,
        productsPerPage: 20
      }
    }

  },
  mounted() {
    this.getProducts()
  },
  computed: {
    ...mapGetters({
      activeLanguage: 'getTrxLanguage'
    }),
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount)
    },
    pageCount() {
      let perPage = this.pagination.productsPerPage ? this.pagination.productsPerPage : 20
      let orderCount = `${1 + this.pagination.currentPage * perPage - perPage} - ${this.pagination.currentPage * perPage}`,
          count = `${orderCount} of ${this.pagination.maxItems} results`

      if (this.pagination.maxItems < 21) {
        count = `${this.pagination.maxItems} of ${this.pagination.maxItems} results`
      }

      // if (!!this.selection.length) {
      // 	count = `${this.selection.length} ${this.activeLanguage.selected} ${this.activeLanguage.of} ${this.pagData.maxItems} ${this.activeLanguage.results}`
      // }

      return count
    }
  },
  methods: {
    getNextPage() {
      this.getProducts(this.pagination.currentPage + 1, this.pagination.productsPerPage)
    },
    getPrevPage() {
      this.getProducts(this.pagination.currentPage - 1, this.pagination.productsPerPage)
    },
    goToNewProduct() {
      this.$router.push('/trxnewproduct')
    },
    getProducts(page = 1, perPage = 20) {
      let queryString = window.location.search
      let searchParams = new URLSearchParams(queryString)
      let productId = searchParams.get('id')

      if (productId) {
        this.search = productId
        window.history.replaceState(null, '', window.location.pathname);
      }
      let params = {
        search: this.search,
        page,
        perPage
      }

      orderAxios
      .get('product/get', {params})
      .then(({data}) => {
        this.productList = data.product_list
        this.productList.map((item) => {
          item.expand = item.product_id === this.search
          item.edit = false
        })
        this.pagination.maxItems = data.total_products;
        this.pagination.currentPage = data.page;
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })

    },
    expandItem(index) {
      this.productList[index].expand = !this.productList[index].expand
      this.$forceUpdate()
    },
    editItem(index) {
      this.productList[index].edit = !this.productList[index].edit
      this.$forceUpdate()
    },
    dragFile(e, index) {
      e.preventDefault()
      let file = e.dataTransfer.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onloadend = () => {
          this.productList[index].image = reader.result;
          this.productList[index].productImageName = file.name;
        };
        reader.readAsDataURL(file);
      } else {
        console.error("No file selected.");
      }
    },
    uploadLogo(e, index) {
      let file = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        this.productList[index].image = reader.result;
        this.productList[index].productImageName = file.name;
      }
    },
    deleteItem(product) {
      let confirmDelete = confirm(this.activeLanguage.confirmText)

      if (confirmDelete) {
        orderAxios
        .delete(`product/delete/${product.id}`)
        .then(({data}) => {
          EventBus.$emit('showAlertModal', data.message)
          this.getProducts()
        })
        .catch(error => {
          console.error(error)
          EventBus.$emit('showAlertModal', error.response.data.error)
        })
      }

    },
    saveItem(product) {
      let params = {
        name: product.name,
        description: product.description,
        price: product.price,
        weight: product.weight,
        sizeHeight: product.size_height,
        sizeWidth: product.size_width,
        sizeLength: product.size_length,
        productImageName: product.productImageName,
        productImageCode: product.image ? product.image.split(',')[1] : null,
        btw: product.btw_percentage,
        custom_fields: product.custom_fields,
        variations: product.variations,
      }

      orderAxios
      .patch(`product/edit/${product.id}`, params)
      .then(({data}) => {
        EventBus.$emit('showAlertModal', data.message)
        this.getProducts()
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })
    },
    openSelectedProduct() {
      let queryString = window.location.search
      let searchParams = new URLSearchParams(queryString)
      let productId = searchParams.get('id')

      let params = {
        search: productId
      }

      orderAxios
      .get('product/get', {params})
      .then(({data}) => {
        this.productList = data.product_list
        this.productList.map((item) => {
          item.expand = true
          item.edit = false
        })
        this.pagination.maxItems = data.total_products;
        this.pagination.currentPage = data.page;
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })

    }

  },
  components: {}
}

</script>

<style>
.logoTitle {
  display: flex;
  justify-content: center;
}

.logoField {
  width: 50%;
  height: 50%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.logoInner {
  margin-top: auto;
  margin-bottom: auto;
}

.logoImg {
  max-width: 100%;
  max-height: 200px;
  margin: auto;
  width: auto;
  height: auto;
  object-fit: cover;
}

.customFileInput {
  height: 200px;
  position: absolute;
  opacity: 0;
}

.image {
  display: flex;
}

.deleteLogo {
  color: #d8271a;
  z-index: 20;
  position: absolute;
  padding: 7px;
  margin-left: 150px;
  margin-top: -15px;
}

.logoField {
  width: 150px;
  height: 150px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.logoInner {
  margin-top: auto;
  margin-bottom: auto;
}

.logoImg {
  max-width: 100%;
  max-height: 200px;
  margin: auto;
  width: auto;
  height: auto;
  object-fit: cover;
}

.img-responsive {
  margin-bottom: auto;
  margin-right: auto;
}
</style>